import cn from "classnames"
import toast from "react-hot-toast"
import { useState, useRef, useEffect } from "react"
import { Row, Col, Spinner } from "react-bootstrap"
import { useNavigate, useParams, useLocation } from "react-router-dom"
import {
  getCandidateProfiles,
  getCandidateProfileByUuid,
} from "api/candidate/profiles"
import { getSearches } from "api/candidate/searches"
import { useSelector } from "react-redux"
import { generateCandidateParams } from "utils/candidateProfile"
import { getActiveJobs, getJobById } from "api/jobs"
import { pageCall } from "utils/mixpanel"
import { trackAction, filterApplied } from "utils/mixpanel"
import { FILTER_STATE_INIT, TECHNOLOGY_INDUSTRIES } from "constants/candidates"
import { toCamelCase } from "utils/common"
import useIsInViewport from "hooks/useIsInViewport"
import Icon from "components/Icon"
import Header from "components/Header"
import CandidatesSidebar from "components/candidates/Sidebar"
import CardApplicant from "components/candidates/CardApplicant"
import SchedulingLinkModal from "components/candidates/Modals/SchedulingLinkModal"
import StartWithAJobModal from "components/candidates/Modals/StartWithAJobModal"
import ErrorToast from "components/shared/Notifications/ErrorToast"
import EmptyState from "components/candidates/EmptyState"
import LoaderCandidate from "components/candidates/Loader"
import StartFromJob from "components/candidates/StartFromJob"
import CandidateTagsFilters from "components/candidates/TagsFilters"
import HeaderInformation from "components/candidates/HeaderInformation"
import SavedSearches from "components/candidates/SavedSearches"
import FPOState from "components/candidates/FPOState"
import styles from "./Candidates.module.scss"

const SourceCandidatesRoute = () => {
  const { uuid, searchUuid } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const { FILTERS_NAVIGATION } = location?.state || {}
  const { currentEmployer } = useSelector(state => state.auth)
  const { scheduling_link, customer } = currentEmployer
  const firstLoad = useRef(false)
  const endList = useRef()
  const isInViewport = useIsInViewport(endList, {
    rootMargin: "50px 50px",
    threshold: 0,
  })

  const [showSchedulingLinkModal, setShowSchedulingLinkModal] = useState(false)
  const [jobSelected, setJobSelected] = useState(null)

  const [showStartWithAJobModal, setShowStartWithAJobModal] = useState(false)

  const [loadingState, setLoadingState] = useState({
    profile: true,
    candidates: false,
    filters: false,
  })

  const [allowChangeProfile, setChangeProfile] = useState(false)
  const [showSidebar, setShowSidebar] = useState(false)
  const [activeProfile, setActiveProfile] = useState([])
  const [currentSelected, setCurrentSelected] = useState(0)
  const [listCandidates, setListCandidates] = useState([])
  const [metaInfo, setMetaInfo] = useState({})
  const [displayedJobs, setDisplayedJobs] = useState([])
  const [filterState, setFilterState] = useState(null)
  const [savedSearches, setSavedSearches] = useState([])
  const [searchSelect, setSearchSelect] = useState({})

  const prevFilterState = useRef(filterState || FILTER_STATE_INIT)

  const cleanUrl = () => {
    navigate("/source-candidates")
  }

  const handleClose = () => {
    setShowSidebar(false)
    setTimeout(() => {
      cleanUrl()
    }, 100)
  }

  const updateUrl = uuid => {
    navigate(`/source-candidates/profiles/${uuid}`)
  }

  const handleShow = (id, uuid) => {
    setChangeProfile(true)
    setCurrentSelected(id)
    setShowSidebar(true)
    updateUrl(uuid)
  }

  const handleShowSchedulingLinkModal = () => {
    setShowSchedulingLinkModal(true)
    trackAction("Contact Settings Clicked")
  }

  const updateCandidate = (index, data) => {
    const updatedList = [...listCandidates]
    const DATA = {
      ...updatedList[index],
      ...data,
    }

    updatedList[index] = { ...DATA }

    setListCandidates(updatedList)
  }

  const fetchCandidates = (page = 1, filters, reset) => {
    setLoadingState({ ...loadingState, candidates: true })
    const params = generateCandidateParams(filters, page)

    if (reset) setLoadingState({ ...loadingState, filters: true })

    getCandidateProfiles(params)
      .then(({ data }) => {
        const { meta, candidate_profiles } = data

        if (filters && prevFilterState.current !== filters) {
          filterApplied(
            FILTER_STATE_INIT,
            prevFilterState.current,
            filters,
            "Source Candidates",
            meta.total_count
          )
          prevFilterState.current = filters
        }

        reset
          ? setListCandidates(candidate_profiles)
          : setListCandidates(prevCandidates => [
              ...prevCandidates,
              ...candidate_profiles,
            ])

        setMetaInfo(meta)
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        setLoadingState({
          profile: false,
          candidates: false,
          filters: false,
        })
      })
  }

  const fetchJobById = async id => {
    return getJobById(id)
      .then(({ data }) => {
        const updatedFilterState = {
          ...FILTER_STATE_INIT,
          headquarters: [...data?.metro_locations],
          selectedRoles: [data?.role?.name],
          ...(data?.min_years_of_experience
            ? {
                yearsExperience: {
                  min: data?.min_years_of_experience,
                  max: 11,
                },
              }
            : {}),
          selectedIndustries: data?.industry?.name
            ? TECHNOLOGY_INDUSTRIES?.some(
                element => element === data?.industry?.name
              )
              ? TECHNOLOGY_INDUSTRIES
              : [data?.industry?.name]
            : [],
        }

        setFilterState(updatedFilterState)

        return updatedFilterState
      })
      .catch(err => {
        console.log("err", err)
      })
  }

  const getCurrentJobs = () => {
    getActiveJobs({ status: "Active" })
      .then(res => {
        setDisplayedJobs(res.data)
      })
      .catch(err => {
        console.log("err", err)
      })
  }

  const getCandidateProfile = uuid => {
    firstLoad.current = true
    getCandidateProfileByUuid(uuid)
      .then(res => {
        setActiveProfile([res])
        setShowSidebar(true)
      })
      .catch(err => {
        handleClose()
        console.log(err)
        toast.custom(t => <ErrorToast t={t} headline="Something went wrong" />)
      })
  }

  const fetchSavedSearches = () => {
    getSearches()
      .then(res => {
        const { data } = res
        setSavedSearches(data?.searches || [])

        const SELECT_SEARCH = data?.searches?.find(
          search => search.uuid === searchUuid
        )

        if (SELECT_SEARCH?.uuid) {
          const FILTER_INFO = toCamelCase(
            SELECT_SEARCH?.filter?.configuration || {}
          )
          setFilterState(FILTER_INFO)
          setSearchSelect(SELECT_SEARCH)
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    if (!customer?.candidates_active) navigate(`/`)
  }, [customer])

  useEffect(() => {
    if (isInViewport) fetchCandidates(metaInfo?.next_page, filterState)
  }, [endList, isInViewport])

  useEffect(() => {
    if (filterState !== null) fetchCandidates(1, filterState, true)
  }, [filterState])

  useEffect(() => {
    if (uuid && !firstLoad.current) getCandidateProfile(uuid)
  }, [uuid, firstLoad.current])

  useEffect(() => {
    if (FILTERS_NAVIGATION)
      return setFilterState(toCamelCase(FILTERS_NAVIGATION))

    if (filterState === null && !!!searchUuid) setFilterState(FILTER_STATE_INIT)
  }, [FILTERS_NAVIGATION, searchUuid])

  useEffect(() => {
    if (displayedJobs?.length > 0 && savedSearches?.length === 0)
      setShowStartWithAJobModal(true)
  }, [displayedJobs, savedSearches])

  useEffect(() => {
    fetchSavedSearches()
    getCurrentJobs()
    pageCall("Source Candidates", "Sourcing")
  }, [])

  return (
    <>
      <Header title="Source Candidates" />
      <section className={cn(styles.candidates, "content")}>
        <Col lg={12}>
          <Row className="wrapper">
            <StartFromJob
              jobs={displayedJobs}
              setFilters={setFilterState}
              filters={filterState}
              updateSearches={setSavedSearches}
              searches={savedSearches}
              getJobInfo={fetchJobById}
              jobSelected={jobSelected}
              searchSelect={searchSelect}
              setSearchSelect={setSearchSelect}
              cleanUrl={cleanUrl}
            />

            <article className={styles.candidates__header}>
              {!loadingState?.profile && (
                <CandidateTagsFilters
                  filters={filterState}
                  setFilters={setFilterState}
                />
              )}

              <span
                className={styles.candidates__header__settings}
                onClick={handleShowSchedulingLinkModal}
              >
                Contact Settings
                <Icon name="settingsFill" height="18" width="18" />
              </span>
            </article>

            {!loadingState?.profile && (
              <Col lg={8}>
                <HeaderInformation
                  filters={filterState}
                  setFilters={setFilterState}
                  count={metaInfo?.total_count}
                />
              </Col>
            )}

            {!loadingState?.profile ? (
              <>
                <Col lg={8} className={styles.candidates__container}>
                  {listCandidates.map((candidate, index) => (
                    <CardApplicant
                      id={index}
                      key={`${candidate?.id}-candidate-${index}`}
                      candidate={candidate}
                      handleShow={handleShow}
                    />
                  ))}
                  {listCandidates.length > 0 &&
                    metaInfo?.total_count == listCandidates?.length &&
                    !searchSelect?.uuid && (
                      <FPOState
                        filters={filterState}
                        searches={savedSearches}
                        updateSearches={setSavedSearches}
                        searchSelect={searchSelect}
                      />
                    )}

                  {loadingState?.filters && <LoaderCandidate />}

                  {listCandidates.length === 0 && (
                    <EmptyState
                      filters={filterState}
                      updateSearches={setSavedSearches}
                      searches={savedSearches}
                      searchSelect={searchSelect}
                    />
                  )}

                  {metaInfo?.total_count > listCandidates?.length && (
                    <div ref={endList} className={styles.candidatesLoaderMore}>
                      <Spinner animation="border" size="sm" />
                    </div>
                  )}
                </Col>

                <Col lg={4} className={styles.candidates__saved}>
                  <SavedSearches
                    savedSearches={savedSearches}
                    setFilters={setFilterState}
                    searchSelect={searchSelect}
                    setSearchSelect={setSearchSelect}
                  />
                </Col>

                <CandidatesSidebar
                  show={showSidebar}
                  onHide={handleClose}
                  currentSelected={currentSelected}
                  setCurrentSelected={setCurrentSelected}
                  listCandidates={
                    uuid && !allowChangeProfile ? activeProfile : listCandidates
                  }
                  updateCandidate={updateCandidate}
                  metaInfo={metaInfo}
                  fetchCandidates={fetchCandidates}
                  jobs={displayedJobs}
                  updateUrl={updateUrl}
                  currentEmployer={currentEmployer}
                  schedulingLink={scheduling_link}
                  openSchedulingLinkModal={handleShowSchedulingLinkModal}
                  loadingListCandidates={loadingState?.candidates}
                />
              </>
            ) : (
              <div className={styles.candidatesLoader}>
                <Spinner animation="border" />
              </div>
            )}
          </Row>
        </Col>
      </section>

      <SchedulingLinkModal
        show={showSchedulingLinkModal}
        modalClose={setShowSchedulingLinkModal}
        schedulingLink={scheduling_link}
      />

      <StartWithAJobModal
        show={showStartWithAJobModal}
        modalClose={setShowStartWithAJobModal}
        jobs={displayedJobs}
        fetchSavedSearches={fetchSavedSearches}
        getJobInfo={fetchJobById}
        setJobSelected={setJobSelected}
      />
    </>
  )
}

export default SourceCandidatesRoute
