import { useState, useEffect } from "react"
import { trackAction } from "utils/mixpanel"
import SelectInput from "components/shared/SelectInput"
import { CustomOption, CustomValue, Control } from "./Options"
import styles from "./startFromJob.candidate.module.scss"

const SearchFromJob = ({ jobs, onChange, value, errorMessage, modal }) => {
  const [options, setOptions] = useState([])

  const changeValue = value => {
    if (!modal) {
      trackAction("Start from a Applied", { job_posting: value?.label })
    }
    onChange(value)
  }

  useEffect(() => {
    const optionsMap = jobs?.map(job => {
      return {
        label: job.title,
        value: job.id,
        locations: job.metro_locations,
        locationType: job.location_type,
      }
    })

    setOptions(optionsMap)
  }, [jobs])

  return (
    <article className={styles.startFromJob}>
      <div className={styles.candidateConnect__select}>
        <SelectInput
          onChange={changeValue}
          value={value}
          className={styles.perPageDropdown}
          name="role"
          placeholder="Start from a job"
          options={options}
          errorMessage={errorMessage}
          error={errorMessage}
          noOptionsMessage={() => (
            <span>No Active Jobs, Post a Job to Get Started</span>
          )}
          selectStyles={{
            control: base => ({
              ...base,
              height: "50px",
              boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.03)",
              border: "1px solid #E1E4E8",
              borderRadius: "0.375rem",
              fontWeight: "700",
            }),
          }}
          components={{
            SingleValue: CustomValue,
            IndicatorSeparator: () => null,
            Option: CustomOption,
            Control: Control,
          }}
        />
      </div>
    </article>
  )
}

export default SearchFromJob
