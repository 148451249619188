import styles from "./headerInformation.candidate.module.scss"

const HeaderInformation = ({ count }) => {
  return (
    <article className={styles.headerInformation}>
      <div className={styles.headerInformation__tagContainer}>
        <span className={styles.headerInformation__title}>Candidates </span>
        <span className={styles.headerInformation__tag}>{count}</span>
      </div>
    </article>
  )
}

export default HeaderInformation
